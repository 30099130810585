<template lang="">
  <modal-padrao
    ref="modal-inativar-instrumento"
    :max-width="1000"
    :titulo="$t('modulos.instrumento.modal_inativar_instrumento.titulo')"
    :titulo-ok="$t('geral.botoes.confirmar')"
    @ok="inativarInstrumento"
  >
    <div class="mt-3">
      <div class="font-weight-bold text-body-1">
        {{ $t('modulos.instrumento.modal_inativar_instrumento.confirmacao') + '"' + codigoInstrumento + '"' + ' ?' }}
      </div>
      <div class="font-weight-bold mt-5 text-body-1">
        {{ $t('modulos.instrumento.modal_inativar_instrumento.associar_ocorrencia') }}
      </div>
      <tabela-padrao-prime-vue
        v-model="selecionado"
        :dados="tabela.dados"
        class="mt-4"
        :colunas="tabela.colunas"
        :quantidade-itens="tabela.quantidadeItens"
        :por-pagina="tabela.porPagina"
        :quantidade-paginas="tabela.quantidadePaginas"
        :pagina="tabela.paginaAtual"
        :mostrar-acoes="false"
        :selecionar-apenas-um="true"
        :mostra-header="false"
        @paginar="listar"
      />
    </div>
  </modal-padrao>
</template>
<script>
import RegistroOcorrenciaService from '@common/services/cadastros/RegistroOcorrenciaService';
import InstrumentoService from '@common/services/cadastros/InstrumentoService.js';
import helpers from '@common/utils/helpers';

export default {
    props: {
        codigoInstrumento: { type: String, default: null },
    },
    data() {
        return {
            selecionado: [],
            instrumento: null,
            instrumentoId: null,
            tabela: {
                dados: [],
                colunas: [
                    {
                        value: 'data',
                        text: this.$t(
                            'componentes.input_ocorrencia_instrumento.tabela.data'
                        ),
                        sortable: false,
                        formatter: (v) => {
                            if (v) return helpers.formatarDataBr(v);
                        },
                    },
                    {
                        value: 'colaboradorResponsavel.nome',
                        text: this.$t(
                            'componentes.input_ocorrencia_instrumento.tabela.colaborador'
                        ),
                        sortable: false,
                    },
                    {
                        value: 'ocorrenciaPadrao.nome',
                        text: this.$t(
                            'componentes.input_ocorrencia_instrumento.ocorrencia'
                        ),
                        sortable: false,
                    },
                    {
                        value: 'observacao',
                        text: this.$t(
                            'componentes.input_ocorrencia_instrumento.tabela.observacao'
                        ),
                        sortable: false,
                    },
                ],
                quantidadeItens: 0,
                quantidadePaginas: 0,
                paginaAtual: 1,
                porPagina: 10,
            },
        };
    },

    watch: {
        value: {
            handler() {
                this.selecionado = this.value;
            },
            immediate: true,
        },
        selecionado: {
            handler() {
                this.$emit('input', this.selecionado);
            },
        },
    },
    mounted() {
    },
    methods: {
        abrirModal: function (item) {
            this.instrumento = item;
            this.instrumentoId = item.id;
            this.listar();
            this.$refs['modal-inativar-instrumento'].abrirModal();
        },
        listar: function (paginaAtual = 1, porPagina = 10) {
            let parametros = {
                Page: paginaAtual,
                AmountPerPage: porPagina
            };

            this.$store.dispatch('Layout/iniciarCarregamento');

            if (this.instrumentoId) parametros.instrumentoId = this.instrumentoId;
            else return this.$store.dispatch('Layout/terminarCarregamento'); // Só busca se tiver instrumentoId por prop

            RegistroOcorrenciaService.listar(parametros)
                .then((res) => {
                    this.tabela.dados = res.data.items;

                    this.tabela.quantidadeItens = res.data.totalItems;
                    this.tabela.quantidadePaginas = res.data.totalPages;
                    this.tabela.paginaAtual = paginaAtual;
                    this.tabela.porPagina = porPagina;
                })
                .finally(() => {
                    this.$store.dispatch('Layout/terminarCarregamento');
                });
        },
        inativarInstrumento: function () {
            if (this.selecionado.length != 0) {
                let form = {
                    id: this.instrumento.id,
                    flagAtivo: this.instrumento.flagAtivo = false,
                    registroOcorrenciaId: this.selecionado.id
                };
                this.$store.dispatch('Layout/iniciarCarregamento');
                InstrumentoService.alterarStatusInstrumento(form)
                    .then(() => {
                        this.$emit('atualizarListagem');
                    })
                    .finally(() => {
                        this.$store.dispatch('Layout/terminarCarregamento');
                    });
                this.selecionado = [];
            } else {
                let form = {
                    id: this.instrumento.id,
                    flagAtivo: this.instrumento.flagAtivo = false,
                };
                this.$store.dispatch('Layout/iniciarCarregamento');
                InstrumentoService.alterarStatusInstrumento(form)
                    .then(() => {
                        this.$emit('atualizarListagem');
                    })
                    .finally(() => {
                        this.$store.dispatch('Layout/terminarCarregamento');
                    });
            }
            this.$refs['modal-inativar-instrumento'].fecharModal();
        },
    },
};
</script>